import {createStore} from 'vuex'

export default createStore({
  state: {
    activeLink: 'home',
    openMenu: false,
  },
  getters: {
    getActiveLink(state) {
      return state.activeLink
    },
    getOpenMenu(state) {
      return state.openMenu
    }
  },
  mutations: {
    setActiveLink(state, data) {
      state.activeLink = data;
    },
    setOpenMenu(state, data) {
      state.openMenu = data;
    }
  },
  actions: {
    switchLink(context, data) {
      context.commit('setActiveLink', data)
    },
    switchMenu(context, data) {
      context.commit('setOpenMenu', data)
    }
  },
  modules: {}
})
