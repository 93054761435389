import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        top: document.querySelector(to.hash).offsetTop - 79,
        behavior: 'smooth',
      }
    } else {
      return {x: 0, y: 0}
    }
  }
})

export default router
