<template>
  <top :class="[{ mobOut: mobile }, { desk: !mobile }]"/>
  <router-view :class="[{ mobOut: mobile }, { desk: !mobile }]"/>

  <div class="last">
    <div class="content">
      <p class="copy">Copyright © 2023 FARMAK. All Rights Reserved.</p>
      <p class="privacy"><a href="/Privacy_notice_Farmak.ch.pdf" target="_blank">Privacy policy</a></p>
      <p class="inch"><a href="https://inchkiev.ua/" target="_blank">Created by INCHKYIV</a></p>
    </div>
  </div>
</template>

<script>
import top from '@/components/top.vue'
import {mapGetters} from "vuex";

export default {
  name: 'app',
  components: {
    top
  },

  watch: {
    'getOpenMenu'(to, from) {
      if (to === true) {
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
      } else if (to === false) {
        document.getElementsByTagName("body")[0].style.overflow = "visible";
      }
    }
  },

  data: () => ({
    mobile: false
  }),

  computed: {
    ...mapGetters(['getOpenMenu'])
  },
  mounted() {
    this.updateVH();
    this.updateVHMob()
    if (window.matchMedia("(min-width: 1023px)").matches) {
    window.addEventListener("resize", this.updateVH);
    }else {
      window.addEventListener("resize", this.updateVHMob);
    }

    this.$nextTick(() => {
      let ua = navigator.userAgent.toLowerCase();
      let isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
      let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

      if (/iPad|iPhone|iPod/.test(navigator.platform)) {
        this.mobile = true
      }
      if (navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 2 &&
          /MacIntel/.test(navigator.platform)) {
        this.mobile = true
      }

      if (isAndroid || iOS === true) {
        this.mobile = true
      }
    })

  },
  methods: {
    updateVH() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    updateVHMob() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vhMenu", `${vh}px`);
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/style/styles";

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.last {
  background: $blue-60;
  padding: 40px;

  display: flex;
  justify-content: center;

  align-items: center;

  @media screen and (max-width: 1080px) {
    padding: 20px;
  }

  .content {
    max-width: 1280px;
    width: 100%;

    display: grid;
    grid-template-columns: 2fr 1fr 1fr;

    @media screen and (max-width: 1080px) {
      grid-template-columns: repeat(1, 1fr);
    }

    a {
      position: relative;

      &:before {
        content: '';
        width: 0%;
        height: 1px;
        background: white;
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;

        transition: width .2s ease;
      }

      @media screen and (min-width: 1240px) {
        &:hover {
          &:before {
            width: 100%;
          }
        }
      }
    }


    p, a {
      line-height: 130%;
      font-size: 15px;
      color: $white;
      font-family: $reg;
      text-decoration: none;

      display: flex;
      align-items: center;
      letter-spacing: 0.02em;


      @media screen and (max-width: 1080px) {
        font-size: 14px;
      }

      &.privacy {
        padding-left: 40px;
        @media screen and (max-width: 1080px) {
          grid-row-start: 2;
          padding-left: 0;
          padding-top: 40px;
        }
      }

      &.inch {
        padding-left: 40px;
        @media screen and (max-width: 1080px) {
          grid-row-start: 2;
          padding-left: 0;
          padding-top: 40px;
        }
      }

      &.copy {
        @media screen and (max-width: 1080px) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }
    }
  }
}

.mobOut {
  * {
    -webkit-tap-highlight-color: rgba(255, 0, 0, 0) !important;
    outline: none !important;
    cursor: none !important;
  }
}
</style>
